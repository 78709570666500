  import React, {useEffect, useRef, useState} from "react";
  import axios from '../axios';
  import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from '../components/Loader';
  import {Link} from "react-router-dom";
  import {showToast} from "../components/ToastHelper";

  export default function Franchisee() {
    const franchiseeFormData = {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '',
      company_name: '',
      your_position: '',
      country_of_interest: '',
      interest_in_other_countries: '',
      corporate_request: '',
      main_interest: '',
      main_profile_group: '',
      others: '',
      current_liquidity_asset: '',
      capital_to_invest: '',
      experience_in_retail: '',
      experience_in_fb_sector: '',
      company_experience_in_franchisee: '',
      plan_to_extend: '',
      describe_your_current_business: '',
      your_expansion_plan: '',
      agree_privacy_policy: false,
    }

    const [countryList, setCountryList] = useState([]);
    const [formData, setFormData] = useState(franchiseeFormData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      setLoading(true);
      axios.get('/country_list')
          .then((response) => {
            // console.log('Response', response.data.data);
            setCountryList(response.data.data);
          })
          .catch((error) => {
            // console.error('Error fetching data:', error);
            // toast.error(error);
            showToast(error, 'error');
          }).finally(() => {
            setLoading(false);
          });
    }, []);

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });

      if (type === 'checkbox') {
        setFormData({
          ...formData,
          [name]: checked,
        });
      }

      // Clear the error for the field being updated
      setErrors({
        ...errors,
        [name]: undefined,
      });
    };

    const validateForm = () => {
      const errors = {};

      if (!formData.first_name) {
        errors.first_name = 'This field is required';
      }

      if (!formData.last_name) {
        errors.last_name = 'This field is required';
      }

      const emailRegex = /^\S+@\S+\.\S+$/;
      if (!formData.email) {
        errors.email = 'This field is required';
      } else if (!emailRegex.test(formData.email)) {
        errors.email = 'Invalid email format';
      }

      const phoneNumberRegex = /^(\+\d{1,3} ?\(\d{3}\) ?\d{3}-\d{4}|\d{11})$/;
      if (!formData.phone_no) {
        errors.phone_no = 'This field is required';
      } else if (!phoneNumberRegex.test(formData.phone_no.trim())){
        errors.phone_no = "Please enter a valid phone no";
      }

      if (!formData.company_name) {
        errors.company_name = 'This field is required';
      }

      if (!formData.your_position) {
        errors.your_position = 'This field is required';
      }

      if (!formData.country_of_interest) {
        errors.country_of_interest = 'This field is required';
      }

      if (!formData.corporate_request) {
        errors.corporate_request = 'This field is required';
      }

      if (!formData.main_interest) {
        errors.main_interest = 'This field is required';
      }

      if (!formData.main_profile_group) {
        errors.main_profile_group = 'This field is required';
      }

      if (formData.main_profile_group === "Other" && !formData.others) {
        errors.others = 'This field is required';
      }

      if (!formData.current_liquidity_asset) {
        errors.current_liquidity_asset = 'This field is required';
      }

      if (!formData.capital_to_invest) {
        errors.capital_to_invest = 'This field is required';
      }

      if (!formData.experience_in_retail) {
        errors.experience_in_retail = 'This field is required';
      }

      if (!formData.experience_in_fb_sector) {
        errors.experience_in_fb_sector = 'This field is required';
      }

      if (!formData.company_experience_in_franchisee) {
        errors.company_experience_in_franchisee = 'This field is required';
      }

      if (!formData.plan_to_extend) {
        errors.plan_to_extend = 'This field is required';
      }

      if (!formData.agree_privacy_policy) {
        errors.agree_privacy_policy = 'You must agree to the privacy policy';
      }

      // Scroll to the first error field if there are errors
      /*if (Object.keys(errors).length > 0) {
        const firstErrorField = document.querySelector('.text-danger');

        if (firstErrorField) {
          // Scroll to the element causing the error
          firstErrorField.scrollIntoView({ behavior: 'smooth' });
        }
      }*/

      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      if (validateForm()) {
        setLoading(true);
        axios.post('/franchisee', formData)
            .then((response) => {
              showToast(response.data.message, 'success');
              setFormData(franchiseeFormData);
              setErrors({});
            })
            .catch((error) => {
              // console.error('Form submission error', error.response.data.errors);
              if (error.response && error.response.status === 422) {

                const validationErrors = error.response.data.errors;
                Object.keys(validationErrors).forEach((field) => {
                  showToast(validationErrors[field][0], 'error');
                });
              } else {
                console.error('Error updating data:', error);
              }
            })
            .finally(() => {
              setLoading(false);
            });
      } else {
        showToast('Please check and filled all the fields', 'error');
      }
    };

    const scrollToSection = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
        <>
          {/*<div className="loader-wrap">
            <div className="preloader">
              <div className="preloader-close">Preloader Close</div>
              <div id="handle-preloader" className="handle-preloader">
                <div className="animation-preloader">
                  <div className="spinner"></div>
                  <div className="txt-loading">
                    <span data-text-preloader="Y" className="letters-loading">
                      Y
                    </span>
                    <span data-text-preloader="O" className="letters-loading">
                        O
                    </span>
                    <span data-text-preloader="L" className="letters-loading">
                        L
                    </span>
                    <span data-text-preloader="É" className="letters-loading">
                        É
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}

          {/*<div className="product-sidebar">*/}
          {/*  <div className="xs-sidebar-group info-group info-sidebar">*/}
          {/*    <ul className="social-links clearfix">*/}
          {/*      <li><a href="#"><i className="icon fab fa-instagram"></i><span>Facebook</span></a></li>*/}
          {/*      <li><a href="#"><i className="icon fab fa-facebook-f"></i><span>Instagram</span></a></li>*/}
          {/*      /!*<li><a href="index.html"><i className="icon fab fa-tiktok"></i><span>TikTok</span></a></li>*!/*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <header className="main-header style-one">
            <div className="header-lower bg-primary">
              <div className="outer-box">
                <div className="logo-box">
                  <figure className="logo">
                    <Link to="/franchisee">
                      <img src="/assets/images/logo.svg" alt="Yolé Franchisee" />
                    </Link>
                  </figure>
                </div>
                <div className="menu-right-content">
                  <p className="text-white">Welcome to the new era of <br /><strong>ice cream & frozen yogurt</strong></p>
                </div>
              </div>
            </div>


            <div className="sticky-header bg-primary">
              <div className="outer-box">
                <div className="logo-box">
                  <figure className="logo">
                    <Link to="/franchisee">
                      <img src="/assets/images/logo.svg" alt="Yolé Franchisee" />
                    </Link>
                  </figure>
                </div>
                <div className="menu-area">
                  <nav className="main-menu clearfix">
                    {/*Keep This Empty / Menu will come through Javascript*/}
                  </nav>
                </div>
                <div className="menu-right-content clearfix d-sm-none">
                  <p className="text-white">Welcome to the new era of <br /><strong>ice cream & frozen yogurt</strong></p>
                </div>
              </div>
            </div>
          </header>

          <section className="ourstory-section sec-pad">
            <div className="auto-container">
              <div className="sec-title mt-60 centred">
                <h2 className="text-uppercase heading-text">The Franchisee Journey</h2>
                <p className="text-black sub-title">YOLÉ Is Here To Change The Ice Cream & Frozen Yogurt Industry  landscape</p>
                <p className="normal-paragraph">We are looking for strong partners interested in expanding our brand across regions. If you are interested to become a long- <br />term YOLÉ partner, and discover our innovative business model, please fill up the form below. We will contact you shortly, and<br /> inform you about our general Master-franchise conditions and initial investments.</p>
              </div>
              <div className="row align-items-center clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 image-column">
                  <figure className="image-box wow slideInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <img src="/assets/images/master_main.png" alt="The Franchisee Journey" />
                  </figure>
                </div>
              </div>
            </div>
          </section>

          <section className="contact-section bg-primary section-padding pt-0 pb-0" ref={sectionRef} style={{height: '700px'}}>
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 form-column">
                  <div className="form-inner">
                    <form className="default-form" onSubmit={handleSubmit}>
                      <div className="sec-title centred mb-2">
                        <h2 className="text-white">Hello There</h2>
                      </div>
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>First Name <span className="text-danger">*</span></label>
                          <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
                          {errors.first_name && <p className="text-danger error">{errors.first_name}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Last Name <span className="text-danger">*</span></label>
                          <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} />
                          {errors.last_name && <p className="text-danger error">{errors.last_name}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Email <span className="text-danger">*</span></label>
                          <input type="email" name="email" value={formData.email} onChange={handleChange} />
                          {errors.email && <p className="text-danger error">{errors.email}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Phone <span className="text-danger">*</span></label>
                          <input type="text" name="phone_no" value={formData.phone_no} onChange={handleChange} />
                          {errors.phone_no && <p className="text-danger error">{errors.phone_no}</p>}
                          {/*<input name="phone" id="phone_no" className="form-control" defaultValue type="text" />*/}
                          {/*<span id="valid-msg" className="hide">✓ Valid</span>
                          <span id="error-msg" className="hide" />*/}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Company Name/Website <span className="text-danger">*</span></label>
                          <input type="text" name="company_name" value={formData.company_name} onChange={handleChange} />
                          {errors.company_name && <p className="text-danger error">{errors.company_name}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Your position in the company <span className="text-danger">*</span></label>
                          <input type="text" name="your_position" value={formData.your_position} onChange={handleChange} />
                          {errors.your_position && <p className="text-danger error">{errors.your_position}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Country Of interest <span className="text-danger">*</span></label>
                          <select name="country_of_interest" className="form-control" value={formData.country_of_interest} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            {countryList.map((country) => (
                                <option value={country} key={country}>{country}</option>
                            ))}
                          </select>
                          {errors.country_of_interest && <p className="text-danger error">{errors.country_of_interest}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Please, indicate if you are interested in other countries/regions</label>
                          <input type="text" name="interest_in_other_countries" value={formData.interest_in_other_countries} onChange={handleChange} />
                          {errors.interest_in_other_countries && <p className="text-danger error">{errors.interest_in_other_countries}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Is this application a personal or corporate request? <span className="text-danger">*</span></label>
                          <select name="corporate_request" className="form-control" value={formData.corporate_request} onChange={handleChange}>
                            <option value="">Please Choose Profile Type</option>
                            <option value="personal">Personal</option>
                            <option value="corporate">Corporate</option>
                          </select>
                          {errors.corporate_request && <p className="text-danger error">{errors.corporate_request}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>What is your main interest? <span className="text-danger">*</span></label>
                          <select name="main_interest" className="form-control" value={formData.main_interest} onChange={handleChange}>
                            <option value="">Please Choose Main Profile Type</option>
                            <option value="To open 1 store in my region">To open 1 store in my region</option>
                            <option value="To open few stores in my region">To open few stores in my region</option>
                            <option value="To expand YOLE across a region/country in exclusivity as the Master-Franchisee">To expand YOLE across a region/country in exclusivity as the Master-Franchisee</option>
                          </select>
                          {errors.main_interest && <p className="text-danger error">{errors.main_interest}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Main Profile of your group? <span className="text-danger">*</span></label>
                          <select name="main_profile_group" className="form-control" value={formData.main_profile_group} onChange={handleChange}>
                            <option value="">Please Choose Main Profile Group</option>
                            <option value="Holding Group">Holding Group</option>
                            <option value="Franchise Operator">Franchise Operator</option>
                            <option value="Family Group">Family Group</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.main_profile_group && <p className="text-danger error">{errors.main_profile_group}</p>}
                        </div>

                        {formData.main_profile_group === "Other" && (
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <label>If "Other", please specify <span className="text-danger">*</span></label>
                            <textarea name="others" value={formData.others} onChange={handleChange} />
                            {errors.others && <p className="text-danger error">{errors.others}</p>}
                          </div>
                        )}

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Current Liquidity Asset (Cash) <span className="text-danger">*</span></label>
                          <select name="current_liquidity_asset" className="form-control" value={formData.current_liquidity_asset} onChange={handleChange}>
                            <option value="">Please Choose Current Liquidity Asset</option>
                            <option value="Less than $100k (USD)">Less than $100k (USD)</option>
                            <option value="$250k - $500K (USD)">$250k - $500K (USD)</option>
                            <option value="$500k - $1M (USD)">$500k - $1M (USD)</option>
                            <option value="More than $1M (USD)">More than $1M (USD)</option>
                          </select>
                          {errors.current_liquidity_asset && <p className="text-danger error">{errors.current_liquidity_asset}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>What is your capital to invest in the YOLE project? <span className="text-danger">*</span></label>
                          <select name="capital_to_invest" className="form-control" value={formData.capital_to_invest} onChange={handleChange}>
                            <option value="">Please Choose Capital Invest</option>
                            <option value="Less than $100k (USD)">Less than $100k (USD)</option>
                            <option value="$250k - $500K (USD)">$250k - $500K (USD)</option>
                            <option value="$500k - $1M (USD)">$500k - $1M (USD)</option>
                            <option value="More than $1M (USD)">More than $1M (USD)</option>
                          </select>
                          {errors.capital_to_invest && <p className="text-danger error">{errors.capital_to_invest}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Do you or your company have experience in retail? <span className="text-danger">*</span></label>
                          <select name="experience_in_retail" className="form-control" value={formData.experience_in_retail} onChange={handleChange}>
                            <option value="">Please Choose Experience In Retail</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          {errors.experience_in_retail && <p className="text-danger error">{errors.experience_in_retail}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Do you or your company have experience in F&amp;B sector? <span className="text-danger">*</span></label>
                          <select name="experience_in_fb_sector" className="form-control" value={formData.experience_in_fb_sector} onChange={handleChange}>
                            <option value="">Please Choose F&amp;B Experience In Retail</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          {errors.experience_in_fb_sector && <p className="text-danger error">{errors.experience_in_fb_sector}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Do you or your company have experience in franchises? <span className="text-danger">*</span></label>
                          <select name="company_experience_in_franchisee" className="form-control" value={formData.company_experience_in_franchisee} onChange={handleChange}>
                            <option value="">Please Choose Franchise Experience</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          {errors.company_experience_in_franchisee && <p className="text-danger error">{errors.company_experience_in_franchisee}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>How many units would you plan to expand in the next 5 years? <span className="text-danger">*</span></label>
                          <select name="plan_to_extend" className="form-control" value={formData.plan_to_extend} onChange={handleChange}>
                            <option value="">Please Choose Units To Open</option>
                            <option value="From 1 to 6 units">From 1 to 6 units</option>
                            <option value="From 6 to 10 units">From 6 to 10 units</option>
                            <option value="From 11 to 20 units">From 11 to 20 units</option>
                            <option value="From 21 to 30 units">From 21 to 30 units</option>
                            <option value="From 31 to 50 units">From 31 to 50 units</option>
                            <option value="From 51 to 100 units">From 51 to 100 units</option>
                            <option value="More than 100 units">More than 100 units</option>
                          </select>
                          {errors.plan_to_extend && <p className="text-danger error">{errors.plan_to_extend}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>If you apply as a group/company, please describe your current business</label>
                          <textarea name="describe_your_current_business"  value={formData.describe_your_current_business} onChange={handleChange} />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>If you apply as Masterfranchise, we are interested to hear about your expansion plan</label>
                          <textarea name="your_expansion_plan"  value={formData.your_expansion_plan} onChange={handleChange} />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Yole's Privacy Policy <span className="text-danger">*</span></label><br/>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" name="agree_privacy_policy" className="custom-control-input" id="consent" checked={formData.agree_privacy_policy} onChange={handleChange}/>
                            <label className="custom-control-label" htmlFor="consent">Yes, I consent</label>
                          </div>
                          <p>In order for us to provide you with this service we need to process the personal data that we collect from you in accordance with our Privacy Policy:</p>
                          <Link href="https://9b94ded0-2fba-4b33-8e5e-bd60a24a329f.filesusr.com/ugd/71c4a1_806a6926d5d343128019060042be02bc.pdf" className="terms-and-condition" target={"_blank"} rel="noopener noreferrer">https://9b94ded0-2fba-4b33-8e5e-bd60a24a329f.filesusr.com/ugd/71c4a1_806a6926d5d343128019060042be02bc.pdf</Link>
                          {errors.agree_privacy_policy && <p className="text-danger error">{errors.agree_privacy_policy}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                          <button className="theme-btn-one bg-primary w-100" type="submit" name="submit-form">Submit Now</button>
                        </div>
                      </div>
                    </form>

                    {loading && <Loader />}
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="promotion-section section-padding pb-0" style={{backgroundImage: 'url(assets/images/bg-gray.png)'}}>
            <div className="auto-container">
              <div className="sec-title centred">
                <h2 className="heading-text">YOLÉ PRODUCT OFFERING</h2>
                <p className="sub-title text-black">The Yolé menu adapted to you own <br /> selection to local market</p>
              </div>
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-5 col-md-12 col-sm-12 content-column">
                    <div className="mr-10">
                      <p className="text-justify mb-10">YOLÉ, the no sugar added ice cream and frozen yogurt brand, is constantly working in our R&D department looking for new flavours, new products, winter season items, new milestones… offering an immense world of healthy possibilities and attracting consumers by the amazing taste and high quality.</p>
                      <p className="text-justify">We work ahead of the ever-changing consumer demand, catching the latest consumer trends. With our obsession to innovate, zero grams of sugar added is our first milestone.</p>
                      <div className="btn-box mt-30"><button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Now</button></div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 col-sm-12 image-column d-flex justify-content-end">
                    <div className="image-box ml-10">
                      <figure className="image"><img src="/assets/images/MASTER_FRANCHISEE__PRODUCTS.png" alt="YOLÉ PRODUCT OFFERING" /></figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-30 pb-30 bg-secondary centred">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="text-white">SMART MODULAR ECO FRIENDLY KIOSKS SYSTEM</h3>
              </div>
            </div>
          </section>


          <section className="service-section section-padding centred bg-primary-light remove-before">
            <div className="auto-container">
              <div className="sec-title yole-boosting">
                <p className="text-uppercase text-black sub-title">BOASTING AN AVANT-GARDE DESIGN BASED ON MINIMALIST LOOK</p>
                <p className="normal-paragraph">Opening a YOLE franchise means starting the business with uncompromising quality,<br /> excellent team, and and revolutionary brand which invests in innovative and eco-friendly<br /> stores/kiosks like never seen before.</p>
              </div>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 service-block">
                  <img src="/assets/images/SMART_MODULAR.png" alt="BOASTING AN AVANT-GARDE DESIGN BASED ON MINIMALIST LOOK" />
                    <div className="btn-box mt-25">
                      <button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Order</button>
                    </div>
                </div>
              </div>
            </div>
          </section>


          <section className="promotion-section section-padding" style={{backgroundImage: 'url(assets/images/bg-gray.png)'}}>
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="image-box ml-10">
                      <figure className="image"><img src="/assets/images/os.png" alt="THE Y-APP OPERATIONS SYSTEM" /></figure>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column text-right">
                    <div className="mr-10">
                      <h2 className="heading-text">THE Y-APP OPERATIONS SYSTEM</h2>
                      <p className="text-black mb-10">A <span className="heading-text">Franchise</span> Platform To Control And <br />Supervise All Your Stores</p>
                      <p className="mb-10">We are changing the way to control, audit, communicate, and train your staff in store. Our software will monitor all the daily processes followed by your staff. You will be able to seamlessly monitor and control your multiple stores in real time.</p>
                      <p className="mb-10">Improving the front service to customers, standardizing the service worldwide, and making smarter YOLÉ staffs will help you to generate YOLÉ loyal customers.</p>
                      <p>
                        <small>
                          Operational management module in-store for employees. <br />
                          Incident management module from each store. <br />
                          News, promotions and instructions module. <br />
                          Training and education module (with videos and exams). <br />
                          Franchise communication module between employees and director.
                        </small>
                      </p>
                      <div className="btn-box mt-25"><button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Now</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="promotion-section section-padding bg-primary-light remove-before">
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column d-flex align-items-center">
                    <div className="mr-10">
                      <h2 className="heading-text">THE Y-CLOUD SYSTEM</h2>
                      <p className="">A <span className="heading-text">Franchise</span> Cloud To Get Access To All Yolé Documentations</p>
                      <p className="normal-paragraph text-justify">A new friendly-user platform makes it easy to get any tool and documentation at any point of time. We are changing the way to automatically access and share all our documentation (manuals, training videos, marketing tools, operational procedures…)</p>
                    </div>
                  </div>
                  <div className="col-lg-4 offset-lg-2 col-md-12 col-sm-12 image-column d-flex align-items-center">
                    <div className="image-box ml-10">
                      <figure className="image"><img src="/assets/images/cloud_system.png" alt="THE Y-CLOUD SYSTEM" /></figure>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 centred">
                    <div className="sec-title mt-50 mb-0">
                      <h2 className="heading-text">THE Y-SALES SYSTEM</h2>
                      <p className="sub-title text-black">Yolé  <span className="heading-text">Captures And Filters </span>Potential Sub-Franchisees For You</p>
                      <p className="normal-paragraph mb-0">As part of the YOLÉ family, we will help you to find single and multi-franchisees partners for your local market. The potential <br /> candidates will interact with our system, and our system will filter and pre-select the candidates before sending them to you.<br /> The final selection will be on your own decision, and by your own parameters.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 offset-lg-3 col-sm-12 text-center">
                    <img src="/assets/images/MASTER_FRANCHISEE___SALES_VISUAL.png" alt="THE Y-SALES SYSTEM" />
                      <div className="btn-box mt-25">
                        <button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Order</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="centred bg-primary-light">
            <div className="row bg-secondary">
              <div className="col-lg-12">
                <h3 className="text-white pt-30 pb-30">THE FMCG CHANNEL</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <img src="/assets/images/MASTER_FRNCHISEE_TUBS_VISUAL.png" alt="The FMCG Channel" />
              </div>
            </div>

            <div className="auto-container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="sec-title">
                    <h2 className="heading-text mt-50 mb-20">OPTION TO ACQUIRE THE FMCG <br /> DISTRIBUTION CHANNEL</h2>
                    <p className="normal-paragraph text-black">Does your group have the infrastructure to import and distribute frozen goods? Are you interested to include the YOLÉ ice cream and frozen <br /> yogurt in your portfolio for supermarkets and convenience stores? If so, please let us know during your application. We have an amazing <br /> range of products ready for distribution in your country.</p>
                    <p className="normal-paragraph text-black">But no worries! If you are just focused on the retail sector, we will find a distributor partner to spread the YOLÉ word into supermarkets and <br /> convenience stores. That will give YOLÉ a high visibility overall, and will help you boost sales in your own retail stores.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*<section className="promotion-section section-padding centred" style={{backgroundImage: 'url(assets/images/bg-gray.png)'}}>
            <div className="auto-container">
              <div className="sec-title centred">
                <h2 className="heading-text">Zero sugar added ice cream and frozen yogurt</h2>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <iframe width={800} height={450} src="https://www.youtube.com/embed/MtllGTdKgbc?playlist=MtllGTdKgbc&loop=1&autoplay=1&mute=1&controls=0&&showinfo=1" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                </div>
              </div>
            </div>
          </section>*/}

          <footer className="main-footer centred">
            <div className="footer-bottom">
              <div className="auto-container clearfix">
                <p className="text-white">Checkout our latest news at <Link href="https://www.yole.com/whats-new" target="_blank" rel="noopener noreferrer">https://www.yole.com/whats-new</Link></p>
              </div>
            </div>
          </footer>

          <button className="scroll-top scroll-to-target" data-target="html">
            <span className="icon-Arrow-Up"></span>
          </button>
        </>
      );
  }