  import React, {useEffect, useRef, useState} from "react";
  import axios from '../axios';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from '../components/Loader';
  import {Link} from "react-router-dom";

  export default function Distributor() {
    const distributorFormData = {
      company: '',
      website: '',
      email: '',
      country: '',
      additional_contact_email: '',
      your_position: '',
      are_you_a: '',
      import_frozen_goods: '',
      distribute_frozen_yogurt: '',
      yes_specify_frozen_yogurt: '',
      distribution_channels: [],
      annual_volume: [],
      others: '',
      import_per_year: '',
      annual_gross_profit: '',
      number_of_employees: '',
      company_businesses_other_sector: '',
      yes_specify: '',
      capacity_to_distribute: '',
      interested_in_exclusivity_rights: '',
      describe_your_business: '',
      share_additional_comments: '',
      agree_privacy_policy_consent: false,
    }

    const [countryList, setCountryList] = useState([]);
    const [formData, setFormData] = useState(distributorFormData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      setLoading(true);
      axios.get('/country_list')
          .then((response) => {
            // console.log('Response', response.data.data);
            setCountryList(response.data.data);
          })
          .catch((error) => {
            // console.error('Error fetching data:', error);
            toast.error(error);
          }).finally(() => {
            setLoading(false);
          });
    }, []);

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });

      if (e.target.multiple) {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setFormData({
          ...formData,
          [name]: selectedOptions,
        });

        // Check if "Other" is selected in the annual_volume field
        if (name === "annual_volume" && selectedOptions.includes("Other")) {
          setFormData({
            ...formData,
            [name]: selectedOptions,
            others: "", // Reset the others field when "Other" is selected
          });
        } else {
          setFormData({
            ...formData,
            [name]: selectedOptions,
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }

      if (type === 'checkbox') {
        setFormData({
          ...formData,
          [name]: checked,
        });
      }

      // Clear the error for the field being updated
      setErrors({
        ...errors,
        [name]: undefined,
      });
    };

    const validateForm = () => {
      const errors = {};

      if (!formData.company) {
        errors.company = 'This field is required';
      }

      if (!formData.website) {
        errors.website = 'This field is required';
      }

      if (!formData.email) {
        errors.email = 'This field is required';
      } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
        errors.email = 'Invalid email format';
      }

      if (!formData.country) {
        errors.country = 'This field is required';
      }

      /*if (!formData.additional_contact_email) {
        errors.additional_contact_email = 'This field is required';
      }*/

      if (!formData.your_position) {
        errors.your_position = 'This field is required';
      }

      if (!formData.are_you_a) {
        errors.are_you_a = 'This field is required';
      }

      if (!formData.import_frozen_goods) {
        errors.import_frozen_goods = 'This field is required';
      }

      if (!formData.distribute_frozen_yogurt) {
        errors.distribute_frozen_yogurt = 'This field is required';
      }

      if (formData.distribute_frozen_yogurt === "yes" && !formData.yes_specify_frozen_yogurt) {
        errors.yes_specify_frozen_yogurt = 'This field is required';
      }

      if (formData.distribution_channels.length === 0) {
        errors.distribution_channels = 'Please select at least one option';
      }

      if (formData.annual_volume.length === 0) {
        errors.annual_volume = 'Please select at least one option';
      }

      if (formData.annual_volume.includes("Other") && !formData.others) {
        errors.others = 'This field is required because "Other" option is selected';
      }

      /*if (!formData.others) {
        errors.others = 'This field is required';
      }*/

      if (!formData.import_per_year) {
        errors.import_per_year = 'This field is required';
      }

      if (!formData.annual_gross_profit) {
        errors.annual_gross_profit = 'This field is required';
      }

      if (!formData.number_of_employees) {
        errors.number_of_employees = 'This field is required';
      }

      if (!formData.company_businesses_other_sector) {
        errors.company_businesses_other_sector = 'This field is required';
      }

      if (formData.company_businesses_other_sector === "yes" && !formData.yes_specify) {
        errors.yes_specify = 'This field is required';
      }

      /*if (!formData.yes_specify) {
        errors.yes_specify = 'This field is required';
      }*/

      if (!formData.capacity_to_distribute) {
        errors.capacity_to_distribute = 'This field is required';
      }

      if (!formData.agree_privacy_policy_consent) {
        errors.agree_privacy_policy_consent = 'You must agree to the privacy policy';
      }

      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      if (validateForm()) {
        setLoading(true);
        axios.post('/distributor', formData)
            .then((response) => {

              // console.log('Form submitted successfully', response.data);
              toast.success(response.data.message);
              setFormData(distributorFormData);
              setErrors({});
            })
            .catch((error) => {
              // console.error('Form submission error', error);
              toast.error(error.data.message);
            })
            .finally(() => {
              setLoading(false);
            });
      }
    };

    const scrollToSection = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
        <>
          {/*<div className="loader-wrap">
            <div className="preloader">
              <div className="preloader-close">Preloader Close</div>
              <div id="handle-preloader" className="handle-preloader">
                <div className="animation-preloader">
                  <div className="spinner"></div>
                  <div className="txt-loading">
                            <span data-text-preloader="Y" className="letters-loading">
                                Y
                            </span>
                    <span data-text-preloader="O" className="letters-loading">
                                O
                            </span>
                    <span data-text-preloader="L" className="letters-loading">
                                L
                            </span>
                    <span data-text-preloader="É" className="letters-loading">
                                É
                            </span>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}

          <header className="main-header style-one">
            <div className="header-lower bg-primary">
              <div className="outer-box">
                <div className="logo-box">
                  <figure className="logo"><Link to="/distributor"><img src="/assets/images/logo.svg" alt="Yolé Distributor" /></Link></figure>
                </div>
                <div className="menu-right-content">
                  <p className="text-white">Welcome to the new era of <br /><strong>ice cream & frozen yogurt</strong></p>
                </div>
              </div>
            </div>


            <div className="sticky-header bg-primary">
              <div className="outer-box">
                <div className="logo-box">
                  <figure className="logo"><Link to="/distributor"><img src="/assets/images/logo.svg" alt="Yolé Distributor" /></Link></figure>
                </div>
                <div className="menu-area">
                  <nav className="main-menu clearfix">
                  </nav>
                </div>
                <div className="menu-right-content clearfix d-sm-none">
                  <p className="text-white">Welcome to the new era of <br /><strong>ice cream & frozen yogurt</strong></p>
                </div>
              </div>
            </div>
          </header>

          <section className="ourstory-section sec-pad pb-60">
            <div className="yole-container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="sec-title mt-60 mb-0">
                    <h2 className="text-uppercase heading-text">The Franchisee Journey</h2>
                    <div className="text-width">
                      <p className="text-black sub-title">YOLÉ Is Here To Change The Ice Cream & Frozen Yogurt Industry</p>
                      <p className="normal-paragraph text-justify">We are looking for distributors/importers partners excited to spread the YOLE across their regions. If you are interested in distributing YOLÉ in supermarkets, convenience stores, please fill up the form below. We will contact you shortly, and inform you about our YOLÉ distribution details.</p>
                      <p className="normal-paragraph text-justify">YOLÉ is a great opportunity! Become our YOLÉ partner, and discover all the benefits to be part of our family. We are not only supporting the penetration and brand expansion, but also developing in parallel an online and offline brand awareness thanks to our YOLÉ franchise stores.</p>
                      <p className="normal-paragraph text-justify">We are not only supporting the penetration and brand expansion, but also developing in parallel an online and offline brand awareness thanks to our YOLÉ franchise stores.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 image-column">
                  <figure className="image-box wow slideInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <img src="/assets/images/banner_distribution_form_copy.png" alt="The Franchisee Journey" />
                  </figure>
                </div>
              </div>
            </div>
          </section>


          <section className="contact-section bg-primary section-padding pt-0 pb-0" ref={sectionRef} style={{height: '700px'}}>
            <div className="yole-container">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 form-column">
                  <div className="form-inner">
                    <div className="sec-title centred mb-2">
                      <h2 className="text-white">Hello There</h2>
                    </div>
                    <form className="default-form" onSubmit={handleSubmit}>
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Company *</label>
                          <input type="text" name="company" value={formData.company} onChange={handleChange} />
                          {errors.company && <p className="text-danger error">{errors.company}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Website *</label>
                          <input type="text" name="website" value={formData.website} onChange={handleChange} />
                          {errors.website && <p className="text-danger error">{errors.website}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Email *</label>
                          <input type="email" name="email" value={formData.email} onChange={handleChange} />
                          {errors.email && <p className="text-danger error">{errors.email}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Country *</label>
                          <select name="country" className="form-control" value={formData.country} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            {countryList.map((country) => (
                                <option value={country} key={country}>{country}</option>
                            ))}
                          </select>
                          {errors.country && <p className="text-danger error">{errors.country}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Additional contact email</label>
                          <input type="text" name="additional_contact_email" value={formData.additional_contact_email} onChange={handleChange} />
                          {/*{errors.additional_contact_email && <p className="text-danger error">{errors.additional_contact_email}</p>}*/}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Your position in the company *</label>
                          <input type="text" name="your_position" value={formData.your_position} onChange={handleChange} />
                          {errors.your_position && <p className="text-danger error">{errors.your_position}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Are you a *</label>
                          <select name="are_you_a" className="form-control" value={formData.are_you_a} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Importer/distributor, and I have my own infrastructure for cold logistic">Importer/distributor, and I have my own infrastructure for cold logistic</option>
                            <option value="Importer/distributor, but I don't have my own infrastructure (cold logistic)">Importer/distributor, but I don't have my own infrastructure (cold logistic)</option>
                            <option value="Direct Purchaser (Supermarket Chain, convenience store…)">Direct Purchaser (Supermarket Chain, convenience store…)</option>
                            <option value="Agent or third party (No a direct purchaser/distributor)">Agent or third party (No a direct purchaser/distributor)</option>
                          </select>
                          {errors.are_you_a && <p className="text-danger error">{errors.are_you_a}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Does your company currently import frozen goods? *</label>
                          <select name="import_frozen_goods" className="form-control" value={formData.import_frozen_goods} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          {errors.import_frozen_goods && <p className="text-danger error">{errors.import_frozen_goods}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Does your company currently distribute any ice cream or frozen yogurt brand? *</label>
                          <select name="distribute_frozen_yogurt" className="form-control" value={formData.distribute_frozen_yogurt} onChange={handleChange}>
                            <option value="">Please Choose Profile Type</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          {errors.distribute_frozen_yogurt && <p className="text-danger error">{errors.distribute_frozen_yogurt}</p>}
                        </div>
                        {formData.distribute_frozen_yogurt === "yes" && (
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <label>If "Yes", please specify</label>
                              <textarea name="yes_specify_frozen_yogurt" className="form-control" value={formData.yes_specify_frozen_yogurt} onChange={handleChange}></textarea>
                              {errors.yes_specify_frozen_yogurt && <p className="text-danger error">{errors.yes_specify_frozen_yogurt}</p>}
                            </div>
                        )}
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>What are your main distribution channels? *</label>
                          <select name="distribution_channels" className="form-control multiple-select-dropdown" multiple="multiple" value={formData.distribution_channels} onChange={handleChange} style={{height:'auto'}}>
                            <option value="Horeca">Horeca</option>
                            <option value="Small Retailers">Small Retailers</option>
                            <option value="Supermarkets & Convenience Stores">Supermarkets & Convenience Stores</option>
                            <option value="Online">Online</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.distribution_channels && <p className="text-danger error">{errors.distribution_channels}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Which channel/s represent at least the 70% of your annual volume? *</label>
                          <select name="annual_volume" className="form-control multiple-select-dropdown" multiple="multiple" value={formData.annual_volume} onChange={handleChange} style={{height:'auto'}}>
                            <option value="Horeca">Horeca</option>
                            <option value="Small Retailers">Small Retailers</option>
                            <option value="Supermarkets & Convenience Stores">Supermarkets & Convenience Stores</option>
                            <option value="Online">Online</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.annual_volume && <p className="text-danger error">{errors.annual_volume}</p>}
                        </div>
                        {formData.annual_volume.includes("Other") && (
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <label>If "OTHER", please specify  channel *</label>
                            <textarea name="others" className="form-control" value={formData.others} onChange={handleChange}></textarea>
                            {errors.others && <p className="text-danger error">{errors.others}</p>}
                          </div>
                        )}
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>How many containers (40sqft) do you usually import per year? *</label>
                          <select name="import_per_year" className="form-control" value={formData.import_per_year} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="From 6 to 10 containers">From 6 to 10 containers</option>
                            <option value="From 11 to 20 containers">From 11 to 20 containers</option>
                            <option value="From 21 to 30 containers">From 21 to 30 containers</option>
                            <option value="From 31 to 50 containers">From 31 to 50 containers</option>
                            <option value="From 51 to 100 containers">From 51 to 100 containers</option>
                            <option value="More than 100 containers">More than 100 containers</option>
                          </select>
                          {errors.import_per_year && <p className="text-danger error">{errors.import_per_year}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>What is your average Annual Gross Profit? *</label>
                          <select name="annual_gross_profit" className="form-control" value={formData.annual_gross_profit} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Up to $250k">Up to $250k</option>
                            <option value="$250k - $500k">$250k - $500k</option>
                            <option value="$500k - $1M">$500k - $1M</option>
                            <option value="$1M - $10M">$1M - $10M</option>
                            <option value="$10M - $100M">$10M - $100M</option>
                            <option value="More than $100M">More than $100M</option>
                          </select>
                          {errors.annual_gross_profit && <p className="text-danger error">{errors.annual_gross_profit}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Number of employees *</label>
                          <select name="number_of_employees" className="form-control" value={formData.number_of_employees} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Less than 10 employees">Less than 10 employees</option>
                            <option value="From 11 to 20 employees">From 11 to 20 employees</option>
                            <option value="From 20 to 50 employees">From 20 to 50 employees</option>
                            <option value="From 50 to 100 employees">From 50 to 100 employees</option>
                            <option value="From 100 to 1,000 employees">From 100 to 1,000 employees</option>
                            <option value="More than 1,000 employees">More than 1,000 employees</option>
                          </select>
                          {errors.number_of_employees && <p className="text-danger error">{errors.number_of_employees}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Does your company have other businesses in any other sector? *</label>
                          <select name="company_businesses_other_sector" className="form-control" value={formData.company_businesses_other_sector} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          {errors.company_businesses_other_sector && <p className="text-danger error">{errors.company_businesses_other_sector}</p>}
                        </div>
                        {formData.company_businesses_other_sector === "yes" && (
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <label>If "Yes", please specify</label>
                              <textarea name="yes_specify" className="form-control" value={formData.yes_specify} onChange={handleChange}></textarea>
                              {errors.yes_specify && <p className="text-danger error">{errors.yes_specify}</p>}
                            </div>
                        )}
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Does your company have the capacity to distribute YOLE products in...? *</label>
                          <select name="capacity_to_distribute" className="form-control" value={formData.capacity_to_distribute} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Only some regions inside the country requested">Only some regions inside the country requested</option>
                            <option value="Only some regions inside the country requested">Only some regions inside the country requested</option>
                            <option value="Across the country requested">Across the country requested</option>
                            <option value="Several Countries">Several Countries</option>
                            <option value="Globally">Globally</option>
                          </select>
                          {errors.capacity_to_distribute && <p className="text-danger error">{errors.capacity_to_distribute}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Are you interested in exclusivity rights?</label>
                          <select name="interested_in_exclusivity_rights" className="form-control" value={formData.interested_in_exclusivity_rights} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Please describe your current business</label>
                          <textarea name="describe_your_business" value={formData.describe_your_business} onChange={handleChange}></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Please, share any additional comments. As a potential YOLE Distributor, we are interested to hear about your expansion/distribution plans</label>
                          <textarea name="share_additional_comments" value={formData.share_additional_comments} onChange={handleChange}></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Yole's Privacy Policy <span className="text-danger">*</span></label><br/>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" name="agree_privacy_policy_consent" className="custom-control-input" id="consent_distributor" checked={formData.agree_privacy_policy} onChange={handleChange}/>
                            <label className="custom-control-label" htmlFor="consent_distributor">Yes, I consent</label>
                          </div>
                          <p>In order for us to provide you with this service we need to process the personal data that we collect from you in accordance with our Privacy Policy:</p>
                          <Link href="https://9b94ded0-2fba-4b33-8e5e-bd60a24a329f.filesusr.com/ugd/71c4a1_806a6926d5d343128019060042be02bc.pdf" className="terms-and-condition" target={"_blank"} rel="noopener noreferrer">https://9b94ded0-2fba-4b33-8e5e-bd60a24a329f.filesusr.com/ugd/71c4a1_806a6926d5d343128019060042be02bc.pdf</Link>
                          {errors.agree_privacy_policy_consent && <p className="text-danger error">{errors.agree_privacy_policy_consent}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                          <button className="theme-btn-one bg-primary w-100" type="submit" name="submit-form">Submit Now</button>
                        </div>
                      </div>
                    </form>

                    {loading && <Loader />}
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="promotion-section section-padding pb-0" style={{backgroundImage: 'url(assets/images/bg-gray.png)'}}>
            <div className="yole-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                    <div className="sec-title">
                      <h2 className="heading-text">YOLÉ PRODUCT OFFERING</h2>
                      <div className="text-width">
                        <p className="sub-title text-black">Promoting A healthy lifesyle and moving forward</p>
                      </div>
                    </div>
                    <div className="text-width mr-10">
                      <p className="text-justify mb-10">YOLÉ, the no sugar added ice cream and frozen yogurt brand, is constantly working in the R&D department looking for new flavors, new products, winter season items and new milestones,… offering an immense world of healthy possibilities and attracting consumers by amazing taste and high quality.we constantly look for innovation, attractive marketing promotions, and new perspectives to acquire loyal customers, and increase your retention.</p>
                      <p className="text-justify">We work ahead of the ever-changing consumer demand, catching up on the latest in the consumer trends. With our obsession to innovate, zero grams of sugar added is our first milestone.</p>
                      <p className="text-justify">We welcome you to join this exciting journey. Discover the new milestones to come.</p>
                      <div className="btn-box mt-30 pb-60"><button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Now</button></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 image-column position-relative">
                    <img src="/assets/images/second_banner.png" alt="YOLÉ PRODUCT OFFERING" className="position-absolute fixed-bottom" />
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="pt-30 pb-30 bg-secondary centred">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="text-white">YOLE NUTRITIONS</h3>
              </div>
            </div>
          </section>


          <section className="service-section section-padding centred bg-primary-light remove-before">
            <div className="auto-container">
              <div className="sec-title yole-boosting">
                <p className="text-uppercase text-black sub-title">Full Of Taste, Zero Sugar Added, Low Calories</p>
                <p className="normal-paragraph">After dedicating thousands of hours of research with the top experts in their fields, we have developed a unique <br /> recipe with the finest ingredients from Spain to perfect an ice cream full of taste, with no sugar added..</p>
                <p className="normal-paragraph">We never compromise the taste over the calories. Yolé ice cream and frozen yogurt have lower calories, fats, and <br /> sugars than any other brand in the market. With the use of premium natural ingredients, we have created rich, <br /> and full-flavored ice cream and frozen yogurt without adding any sugar.</p>
              </div>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 service-block">
                  <img src="/assets/images/low_calorie.png" alt="Full Of Taste, Zero Sugar Added, Low Calories" />
                    <div className="btn-box mt-25"><button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Now</button></div>
                </div>
              </div>
            </div>
          </section>


          <section className="promotion-section section-padding" style={{backgroundImage: 'url(assets/images/bg-gray.png)'}}>
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="image-box ml-10">
                      <figure className="image"><img src="/assets/images/omni_channel.png" alt="SMART MULTI CHANNEL STRATEGY" /></figure>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column text-right">
                    <div className="mr-10">
                      <h2 className="heading-text">SMART MULTI CHANNEL STRATEGY</h2>
                      <p className="text-black mb-10">Boosting <span className="heading-text">awareness & visibility</span> accross your <br /> region</p>
                      <p className="mb-10">YOLE is committed to an omnichannel distribution, multiplying the consumer brand awareness exponentially. The result is higher brand recognition, positioning, and sales.</p>
                      <p className="mb-10">How do we do it? We work together with our partners across the regions, and put in place a smart 360-degrees strategy. We develop the YOLÉ expansion across different channels to increase the visibility of the brand.  Cross-selling and cross-marketing between different YOLÉ partners will allow you to boost the traffic in your point of sales.</p>
                      <div className="btn-box mt-25"><button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Now</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="promotion-section section-padding bg-primary-light remove-before">
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column d-flex align-items-center">
                    <div className="mr-10">
                      <h2 className="heading-text">PRODUCT AND LOGISTIC SPECIFICATIONS</h2>
                      <p className="">Ready To Import Across The World</p>
                      <p className="normal-paragraph text-justify">We have extensive experience importing in many countries across the world. We are ready to provide all the specifications and custom requirements needed to comply with each local legislations. We count with all the product specifications, packaging specifications, international certifications, world-class manufacturers, logistic third parties… and we support and provide with any additional requirements needed for those countries with the most restrictive importation procedures.</p>
                      <p className="normal-paragraph text-justify">Do you require any specific certificate like HALAL or Kosher? Please, let us know!</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column d-flex align-items-center">
                    <div className="image-box ml-10">
                      <figure className="image"><img src="/assets/images/logistics.png" alt="PRODUCT AND LOGISTIC SPECIFICATIONS" /></figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="promotion-section section-padding" style={{backgroundImage: 'url(assets/images/bg-gray.png)'}}>
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix align-items-center">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="image-box ml-10">
                      <figure className="image"><img src="/assets/images/merch.png" alt="YOLE CENTRAL SERVICES" /></figure>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column text-right">
                    <div className="mr-10">
                      <h2 className="heading-text">YOLE CENTRAL SERVICES</h2>
                      <p className="text-black mb-10">Merchandise & Marketing Support</p>
                      <p className="mb-10">We have a marketing team to support and assist to grow the brand across the regions. From the central office, we ensure  that the brand is consistent in all markets, and we help you  to give the proper message to educate consumers and increase the brand awareness.</p>
                      <p className="mb-10">With our extensive marketing and branding experience, we can advise you on local campaigns along the year to promote the brand together with our YOLÉ franchisees.</p>
                      <div className="btn-box mt-25"><button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Now</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          {/*<section className="promotion-section section-padding centred" style={{backgroundImage: 'url(assets/images/bg-gray.png)'}}>
            <div className="auto-container">
              <div className="sec-title centred">
                <h2 className="heading-text">Zero sugar added ice cream and frozen yogurt</h2>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <iframe width={800} height={450} src="https://www.youtube.com/embed/MtllGTdKgbc?playlist=MtllGTdKgbc&loop=1&autoplay=1&mute=1&controls=0&&showinfo=1" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                </div>
              </div>
            </div>
          </section>*/}


          <footer className="main-footer centred">
            <div className="footer-bottom">
              <div className="auto-container clearfix">
                <p className="text-white">Checkout our latest news at <Link href="https://www.yole.com/whats-new" target="_blank" rel="noopener noreferrer">https://www.yole.com/whats-new</Link></p>
              </div>
            </div>
          </footer>


          <button className="scroll-top scroll-to-target" data-target="html">
            <span className="icon-Arrow-Up"></span>
          </button>
        </>
      );
  }