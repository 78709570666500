import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Franchisee from "./pages/Franchisee";
import Distributor from "./pages/Distributor";

function App() {
    const [redirectPath, setRedirectPath] = useState('');

    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/') {
            const yoleURL = window.location.href = 'https://yole.com';
            setRedirectPath(yoleURL);
        }

    }, [redirectPath]);

  return (
    <div className="boxed_wrapper">
      <Router>
        <Routes>
          <Route path="/franchisee" element={<Franchisee />} />
          <Route path="/distributor" element={<Distributor />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
